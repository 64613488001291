import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _delete, _get, _post, _put } from "../../../../api/apiClient";
import { HttpStatusCode } from "axios";
import { API_STATUS } from "../../sliceUtil";
import _ from "lodash";
import { EMAIL_CAMPAIGN_STATUS } from "../../../../Components/Bank/CampaignManagement/campaignConstant";
import { CustomError } from "../../../../api/CustomError";
import { getEmailTemplateById } from "./emailTemplatesSlice";
import { fetchConsumerList } from "./consumerListSlice";
import { setLoaderFalse, setLoaderTrue } from "../../../../Redux/loaderSlice";
import { CustomSuccess } from "../../../../api/CustomSuccess";
import store from "../../../../app/store";

const initEmailCampaignsState = {
  emailCampaigns: null,
  draftEmailCampaigns: null,
  emailCampaignsSummary: null,
  isLoading: API_STATUS.STATUS_IDLE,
  emailCampaignisLoading:API_STATUS.STATUS_IDLE,
  totalEmailCampaigns: 0,
};

// const client_id = "007a1000-0000-0000-0000-000000000001";

const _orgClientId = () => {
  const state = store.getState();
  return state?.orgReducer?.selectedFI?.orgClientId;
};

export const concurrent_fetchEmailCampaigns_fetchDraftEmailCampaigns_fetchEmailCampaignsStatus =
  createAsyncThunk(
    "campaigns/concurrent_fetchEmailCampaigns_fetchDraftEmailCampaigns_fetchEmailCampaignsStatus",
    async ({ paginationObj, disableLoader = false }, thunkAPI) => {
      let isToastErrorOnce = false;
      try {
        if (disableLoader) thunkAPI.dispatch(setLoaderTrue());
        const [
          _fetchEmailCampaigns,
          _fetchDraftEmailCampaigns,
          _fetchEmailCampaignsStatus,
        ] = await Promise.all([
          thunkAPI.dispatch(
            fetchEmailCampaigns({ paginationObj, disableToast: true }),
          ),
          thunkAPI.dispatch(fetchDraftEmailCampaigns({ disableToast: true })),
          thunkAPI.dispatch(fetchEmailCampaignsSummary({ disableToast: true })),
        ]);

        if (_fetchEmailCampaigns?.payload?.statusCode !== HttpStatusCode.Ok) {
          throw CustomError(_fetchEmailCampaigns?.payload, {
            disableToast: isToastErrorOnce,
          });
        }

        if (
          _fetchDraftEmailCampaigns?.payload?.statusCode !== HttpStatusCode.Ok
        ) {
          throw CustomError(_fetchDraftEmailCampaigns?.payload, {
            disableToast: isToastErrorOnce,
          });
        }

        if (
          _fetchEmailCampaignsStatus?.payload?.statusCode !== HttpStatusCode.Ok
        ) {
          throw CustomError(_fetchEmailCampaignsStatus?.payload, {
            disableToast: isToastErrorOnce,
          });
        }
        thunkAPI.dispatch(setLoaderFalse());
      } catch (err) {
        isToastErrorOnce = true;
        thunkAPI.dispatch(setLoaderFalse());
        return thunkAPI.rejectWithValue(err);
      }
    },
  );

export const fetchEmailCampaigns = createAsyncThunk(
  "campaigns/fetchEmailCampaigns",
  async ({ paginationObj, disableToast, disableLoader = false }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Fetched Camapigns successfully.",
        errorMessage: "Failed to fetch camapigns!",
        disableToast: disableToast,
      };

      const _pageNumber = paginationObj?.currentPage
        ? `PageNumber=${paginationObj?.currentPage}`
        : "";
      const _pageSize =
        paginationObj?.limit > 0 ? `&PageSize=${paginationObj?.limit}` : "";

      const _status = paginationObj?.selectedFilter?.code
        ? `&Status=${paginationObj?.selectedFilter?.code}`
        : "";
      const _sortBy = paginationObj?.sortObj?.sortOrder
        ? `&SortBy=${paginationObj?.sortObj?.sortOrder}`
        : "";
      const _sortField = paginationObj?.sortObj?.sortField
        ? `&SortField=${paginationObj?.sortObj?.sortField}`
        : "";
      const _searchBy = paginationObj?.searchData
        ? `&searchBy=${paginationObj?.searchData}`
        : "";

      const response = await _get(
        `${_orgClientId()}/campaign?${_pageNumber}${_pageSize}${_status}${_sortBy}${_sortField}${_searchBy}`,
      );

      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }

      const {
        data: emailCampaigns,
        error,
        message,
        status,
        statusCode,
        responseType,
      } = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });

      thunkAPI.dispatch(setLoaderFalse());
      return {
        emailCampaigns,
        paginationObj,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (err) {
      if (disableLoader) thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchDraftEmailCampaigns = createAsyncThunk(
  "campaigns/fetchDraftEmailCampaigns",
  async ({ disableToast, disableLoader = false }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Fetched Camapigns successfully.",
        errorMessage: "Failed to fetch camapigns!",
        disableToast: disableToast,
      };
      const _pageNumber = `PageNumber=1`;
      const _pageSize = `&PageSize=4`;
      const _status = `&Status=${EMAIL_CAMPAIGN_STATUS?.DRAFT_CAMPAIGN}`;
      const _sortBy = `&SortBy=DESC`;
      const _sortField = `&SortField=created_On`;
      const response = await _get(
        `${_orgClientId()}/campaign?${_pageNumber}${_pageSize}${_status}${_sortBy}${_sortField}`,
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const {
        data: draftEmailCampaigns,
        error,
        message,
        status,
        statusCode,
        responseType,
      } = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });
      thunkAPI.dispatch(setLoaderFalse());
      return {
        draftEmailCampaigns,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (err) {
      if (disableLoader) thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchEmailCampaignsSummary = createAsyncThunk(
  "campaigns/fetchEmailCampaignsSummary",
  async ({ disableToast, disableLoader = false }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Fetched camapigns summary successfully.",
        errorMessage: "Failed to fetch camapigns summary!",
        disableToast: disableToast,
      };
      const response = await _get(`${_orgClientId()}/campaign/summary`);
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const {
        data: emailCampaignsSummary,
        error,
        message,
        status,
        statusCode,
        responseType,
      } = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });

      thunkAPI.dispatch(setLoaderFalse());
      return {
        emailCampaignsSummary,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (err) {
      if (disableLoader) thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const concurrent_getEmailCampaignById_fetchConsumerList =
  createAsyncThunk(
    "campaigns/concurrent_getEmailCampaignById_fetchConsumerList",
    async ({ campaignId, disableLoader = false }, thunkAPI) => {
      try {
        if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());

        const [_getEmailCampaignById, _fetchConsumerList] = await Promise.all([
          thunkAPI.dispatch(
            getEmailCampaignById({ campaignId, disableLoader: true }),
          ),
          thunkAPI.dispatch(
            fetchConsumerList({ paginationObj: {}, disableLoader: false }),
          ),
        ]);

        if (_getEmailCampaignById?.payload?.statusCode !== HttpStatusCode.Ok) {
          CustomError(_getEmailCampaignById?.value?.payload);
        }

        if (_fetchConsumerList?.payload?.statusCode !== HttpStatusCode.Ok) {
          CustomError(_fetchConsumerList?.value?.payload);
        }

        thunkAPI.dispatch(setLoaderFalse());
        return {
          campaignResponse: _getEmailCampaignById?.payload,
          customerlistRes: _fetchConsumerList?.payload,
          statusCode: 200,
        };
      } catch (err) {
        thunkAPI.dispatch(setLoaderFalse());
        return thunkAPI.rejectWithValue(err);
      }
    },
  );

export const cascaded_getEmailCampaignById_getEmailTemplateById_fetchConsumerList =
  createAsyncThunk(
    "campaigns/cascaded_getEmailCampaignById_getEmailTemplateById_fetchConsumerList",
    async ({ campaignId, disableLoader = false }, thunkAPI) => {
      try {
        if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());

        const campaignResponse = await thunkAPI.dispatch(
          getEmailCampaignById({ campaignId, disableLoader: true }),
        );

        if (campaignResponse?.payload?.statusCode !== HttpStatusCode.Ok) {
          CustomError(campaignResponse, campaignConfig);
        }

        const [_fetchEmailTemplates, _fetchConsumerList] = await Promise.all([
          thunkAPI.dispatch(
            getEmailTemplateById({
              templateId:
                campaignResponse?.payload?.campaignResponse?.template_Id,
              disableLoader: false,
            }),
          ),
          thunkAPI.dispatch(
            fetchConsumerList({ paginationObj: {}, disableLoader: false }),
          ),
        ]);

        if (_fetchEmailTemplates?.payload?.statusCode !== HttpStatusCode.Ok) {
          CustomError(_fetchEmailTemplates?.value?.payload);
        }

        if (_fetchConsumerList?.payload?.statusCode !== HttpStatusCode.Ok) {
          CustomError(_fetchConsumerList?.value?.payload);
        }

        thunkAPI.dispatch(setLoaderFalse());
        return {
          campaignResponse: campaignResponse?.payload,
          templateResponse: _fetchEmailTemplates?.payload,
          customerlistRes: _fetchConsumerList?.payload,
          statusCode: 200,
        };
      } catch (err) {
        thunkAPI.dispatch(setLoaderFalse());
        return thunkAPI.rejectWithValue(err);
      }
    },
  );

export const getEmailCampaignById = createAsyncThunk(
  "campaigns/getEmailCampaignById",
  async ({ campaignId, disableLoader = false }, thunkAPI) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Get campaign successfully.",
        errorMessage: "Failed to get camapign!",
      };
      const response = await _get(`${_orgClientId()}/campaign/${campaignId}`);
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const {
        data: campaignResponse,
        error,
        message,
        status,
        statusCode,
        responseType,
      } = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });
      thunkAPI.dispatch(setLoaderFalse());

      return {
        campaignResponse,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (err) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createEmailCampaign = createAsyncThunk(
  "campaigns/createEmailCampaign",
  async ({ campaignObj, disableLoader = false }, thunkAPI) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Campaign created successfully.",
        errorMessage: "Failed to create campaign!",
      };
      const response = await _post(`${_orgClientId()}/campaign`, campaignObj);
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const _response = CustomSuccess(response, {
        ...config,
      });
      thunkAPI.dispatch(setLoaderFalse());
      return _response;
    } catch (err) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateEmailCampaign = createAsyncThunk(
  "campaigns/updateEmailCampaign",
  async (campaignObj, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Campaign updated successfully.",
        errorMessage: "Failed to update campaign!",
      };
      const response = await _put(
        `${_orgClientId()}/campaign/${campaignObj?.uuid}`,
        campaignObj,
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const _response = CustomSuccess(response, {
        ...config,
      });
      thunkAPI.dispatch(setLoaderFalse());

      return _response;
    } catch (err) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteEmailCampaign = createAsyncThunk(
  "campaigns/deleteEmailCampaign",
  async (
    { selectedCampaign, paginationObj, disableLoader = false },
    thunkAPI,
  ) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Campaign deleted successfully.",
        errorMessage: "Failed to delete campaign!",
      };
      const response = await _delete(
        `${_orgClientId()}/campaign/${selectedCampaign?.uuid}`,
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      if (selectedCampaign?.status === EMAIL_CAMPAIGN_STATUS?.DRAFT_CAMPAIGN) {
        await thunkAPI.dispatch(fetchDraftEmailCampaigns({ disableToast: false }));
      }
      await thunkAPI.dispatch(fetchEmailCampaignsSummary({ disableToast: false }));
      await thunkAPI.dispatch(fetchEmailCampaigns({ paginationObj }));
      const _response = CustomSuccess(response, {
        ...config,
      });
      thunkAPI.dispatch(setLoaderFalse());
      return _response;
    } catch (err) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const cancelEmailCampaign = createAsyncThunk(
  "campaigns/cancelEmailCampaign",
  async (
    { selectedCampaign, paginationObj, disableLoader = false },
    thunkAPI,
  ) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Campaign Cancelled successfully.",
        errorMessage: "Failed to cancel campaign!",
      };

      const response = await _delete(
        `${_orgClientId()}/campaign/${selectedCampaign?.uuid}/cancel`,
      );

      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }

      if (selectedCampaign?.status === EMAIL_CAMPAIGN_STATUS?.DRAFT_CAMPAIGN) {
        await thunkAPI.dispatch(fetchDraftEmailCampaigns({ disableToast: false }));
      }
      paginationObj && (await thunkAPI.dispatch(fetchEmailCampaignsSummary({ disableToast: false })));
      paginationObj &&
        (await thunkAPI.dispatch(fetchEmailCampaigns({ paginationObj })));
      const _response = CustomSuccess(response, {
        ...config,
      });
      thunkAPI.dispatch(setLoaderFalse());
      return _response;
    } catch (err) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const emailCampaignsSlice = createSlice({
  name: "emailCampaigns",
  initialState: initEmailCampaignsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmailCampaigns.pending, (_state) => ({
        ..._state,
        emailCampaignisLoading: API_STATUS.STATUS_LOADING,
      }))
      .addCase(fetchEmailCampaigns.fulfilled, (_state, action) => {
        const {currentPage,limit}=action?.payload?.paginationObj;

        let offset = (currentPage - 1) * limit;
        const emailCampaignData = action.payload.emailCampaigns.campaigns?.map(
          (item, index) => {
            return {...item,srNo:offset + index + 1}
          },
        );
        return {
          ..._state,
          emailCampaignisLoading: API_STATUS.STATUS_SUCCESS,
          emailCampaigns: {
            campaigns: emailCampaignData,
            // campaigns: action.payload.emailCampaigns.campaigns,
            totalCount: action.payload.emailCampaigns.totalCount,
          },
        };
      })
      .addCase(fetchEmailCampaigns.rejected, (_state, action) => ({
        emailCampaignisLoading: API_STATUS.STATUS_FAILURE,
        message: action.error.message || "Error while fetching email campaigns",
      }))
      .addCase(fetchDraftEmailCampaigns.pending, (_state) => ({
        ..._state,
        isLoading: API_STATUS.STATUS_LOADING,
      }))
      .addCase(fetchDraftEmailCampaigns.fulfilled, (_state, action) => {
        return {
          ..._state,
          isLoading: API_STATUS.STATUS_SUCCESS,
          draftEmailCampaigns: {
            campaigns: action.payload.draftEmailCampaigns.campaigns,
            totalCount: action.payload.draftEmailCampaigns.totalCount,
          },
        };
      })
      .addCase(fetchDraftEmailCampaigns.rejected, (_state, action) => ({
        isLoading: API_STATUS.STATUS_FAILURE,
        message:
          action.error.message || "Error while fetching draft email campaigns",
      }))
      .addCase(fetchEmailCampaignsSummary.pending, (_state) => ({
        ..._state,
        isLoading: API_STATUS.STATUS_LOADING,
      }))
      .addCase(fetchEmailCampaignsSummary.fulfilled, (_state, action) => {
        return {
          ..._state,
          isLoading: API_STATUS.STATUS_SUCCESS,
          emailCampaignsSummary: action.payload.emailCampaignsSummary,
        };
      })
      .addCase(fetchEmailCampaignsSummary.rejected, (_state, action) => ({
        isLoading: API_STATUS.STATUS_FAILURE,
        message:
          action.error.message ||
          "Error while fetching email campaigns summary",
      }));
  },
});

export const selectEmailCampaigns = (_state) => _state?.emailCampaigns;

export const {} = emailCampaignsSlice.actions;
