// Function to convert category name to a more readable format
export const formatCategoryName = (categoryName) => {
  // Split the category name by underscores and capitalize each word
  if (categoryName) {
    const words = categoryName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    // Join the words back together with a space
    return words.join(" ");
  } else {
    return categoryName;
  }
};
export function capitalizeFirstLetterOfEachWord(str) {
  if (str?.length === 0) return str;
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export function addSuffix(number) {
  const lastDigit = number % 10;
  let suffix = "";
  if (number === 11 || number === 12 || number === 13) {
    suffix = "th";
  } else {
    switch (lastDigit) {
      case 1:
        suffix = "st";
        break;
      case 2:
        suffix = "nd";
        break;
      case 3:
        suffix = "rd";
        break;
      default:
        suffix = "th";
        break;
    }
  }
  return (
    <>
      {number}
      <sup>{suffix}</sup>
    </>
  );
}

export const fnCheckDollarUnits = (value) => {
  let result = { score: value, unit: "" }; // Default values

  if (value !== 0) {
    if (Math.abs(value) >= 1000 && Math.abs(value) <= 999999) {
      result = { score: value / 1000, unit: "K" }; // Thousands
    } else if (Math.abs(value) >= 1000000 && Math.abs(value) <= 999999999) {
      result = { score: value / 1000000, unit: "MM" }; // Millions
    } else if (
      Math.abs(value) >= 1000000000 &&
      Math.abs(value) <= 999999999999
    ) {
      result = { score: value / 1000000000, unit: "B" }; // Billions
    } else if (
      Math.abs(value) >= 1000000000000 &&
      Math.abs(value) <= 999999999999999
    ) {
      result = { score: value / 1000000000000, unit: "T" }; // Trillions
    } else if (
      Math.abs(value) >= 1000000000000000 &&
      Math.abs(value) <= 999999999999999999
    ) {
      result = { score: value / 1000000000000000, unit: "P" }; // Quadrillions (Petas)
    }
  }

  return result;
};
