import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { API_STATUS } from "../sliceUtil";
import { _get } from "../../../api/apiClient";
import { CustomError } from "../../../api/CustomError";
import store from "../../../app/store";
import { setLoaderFalse, setLoaderTrue } from "../../../Redux/loaderSlice";

const initialStripeState = {};

const _orgClientId = () => {
  const state = store.getState();
  return state?.authReducer?.userData?.client_Id;
};

export const fetchInvoiceDetails = createAsyncThunk(
  "/stripe/invoice",
  async ({ disableLoader }, thunkAPI) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Invoice details fetched.",
        errorMessage: "Something went wrong.",
      };
      const response = await _get(`${_orgClientId()}/payment/invoice`, {});
      if (response?.data?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      thunkAPI.dispatch(setLoaderFalse());
      return response;
    } catch (err) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err?.error);
    }
  },
);

export const generateCustomerId = createAsyncThunk(
  "/stripe/createPaymentId",
  async ({ disableLoader }, thunkAPI) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Customer id created.",
        errorMessage: "Something went wrong.",
      };
      thunkAPI.dispatch(setLoaderTrue());
      const response = await _get(`${_orgClientId()}/payment/subscription`, {});
      if (response?.data?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      thunkAPI.dispatch(setLoaderFalse());
      return response;
    } catch (err) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err?.error);
    }
  },
);

export const stripeSlice = createSlice({
  name: "stripe",
  initialState: initialStripeState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceDetails.pending, () => ({
        isLoading: API_STATUS.STATUS_LOADING,
      }))
      .addCase(fetchInvoiceDetails.fulfilled, (_state, action) => {
        return {
          isLoading: API_STATUS.STATUS_SUCCESS,
          invoiceDetails: action.payload,
        };
      })
      .addCase(fetchInvoiceDetails.rejected, (_state, action) => {
        return {
          isLoading: API_STATUS.STATUS_FAILURE,
          message: action.error.message || "Error while creating user",
          invoiceDetails: [],
        };
      });
  },
});

export const {} = stripeSlice.actions;
