import { configureStore, combineReducers } from "@reduxjs/toolkit";
import AsyncStorage from "@react-native-async-storage/async-storage";
import storageSession from "redux-persist/lib/storage/session";
import persistReducer from "redux-persist/lib/persistReducer";
import authReducer from "../Redux/authSlice";
import subscriptionReducer from "../Redux/subscriptionSlice";
import orgReducer from "../Redux/orgDetailsSlice";
import errorReducer from "../Redux/errorSlice";
import loaderReducer from "../Redux/loaderSlice";
// import pre_SignReducer from "../Redux/preSignSlice";
import filterOptionReducer from "../Redux/filterOptions";
// import mainReportSlice from "../Redux/mainReportSlice";
import saveFilterReducer from "../Redux/savedFilterRedux";
import fhsDrilldownReducer from "../Redux/fhsDrilldownRedux";
// import opportunitiesReducer from "../Redux/opportunitiesDataSlice";
// import missingproductReducer from "../Redux/missingDataSlice";
// import secondaryconsumerReducer from "../Redux/secondaryConsumer";
// import ghostconsumerReducer from "../Redux/ghostConsumer";
import navigationHistoryReducer from "../Redux/navigationHistorySlice";
import selectedFilterSlice from "../Redux/selectedFilterSlice";
import financialHealthScoreAnalysisSlice from "../rtk-query/features/dashboard/financialHealthScore/financialHealthScoreAnalysisSlice";
import healthAnalysisSlice from "../rtk-query/features/healthAnalysis/healthAnalysisSlice";
import { opportunitiesAnalysisSlice } from "../rtk-query/features/dashboard/opportunities/opportunitiesAnalysisSlice";
// import { missingProductsAnalysisSlice } from "../rtk-query/features/dashboard/missingProducts/missingProductsAnalysisSlice";
import { ghostConsumerAnalysisSlice } from "../rtk-query/features/dashboard/ghostConsumer/ghostConsumerAnalysisSlice";
import { secondaryConsumerAnalysisSlice } from "../rtk-query/features/dashboard/secondaryConsumer/secondaryConsumerAnalysisSlice";
import { topCompetitorsAnalysisSlice } from "../rtk-query/features/dashboard/topCompetitors/topCompetitorsAnalysisSlice";
import { fileAnalyticsAnalysisSlice } from "../rtk-query/features/dashboard/fileAnalytics/fileAnalyticsAnalysisSlice";
import { missedRevenueAnalysisSlice } from "../rtk-query/features/dashboard/missedRevenue/missedRevenueAnalysisSlice";
import { bondScoreAnalysisSlice } from "../rtk-query/features/dashboard/bondScore/bondScoreAnalysisSlice";
// import { efficiencyRatioAnalysisSlice } from "../rtk-query/features/dashboard/efficiencyRatio/efficiencyRatioAnalysisSlice";
import { topMerchantsAnalysisSlice } from "../rtk-query/features/dashboard/topMerchants/topMerchantsAnalysisSlice";
import { consumerDemographicsAnalysisSlice } from "../rtk-query/features/dashboard/consumerDemographics/consumerDemographicsAnalysisSlice";
import { topPersonaAnalysisSlice } from "../rtk-query/features/dashboard/topPersona/topPersonaAnalysisSlice";
// import { newUserGrowthAnalysisSlice } from "../rtk-query/features/dashboard/newUserGrowth/newUserGrowthAnalysisSlice";
import { trendsAnalysisSlice } from "../rtk-query/features/dashboard/trends/trendsAnalysisSlice";
import { rtkQueryErrorLogger } from "../rtk-query/features/rtkQueryErrorLogger";
import { registrationSlice } from "../rtk-query/features/auth/register/registrationSlice";
import allCohortReducer from "../Redux/allCohortSlice";
import cohortSlice from "../Redux/cohortSlice";
import { emailTemplatesSlice } from "../rtk-query/features/campaign/email-campaign/emailTemplatesSlice";
import { emailCampaignsSlice } from "../rtk-query/features/campaign/email-campaign/emailCampaignsSlice";
import { consumerListSlice } from "../rtk-query/features/campaign/email-campaign/consumerListSlice";
import { consumerListDetailsSlice } from "../rtk-query/features/campaign/email-campaign/consumerListDetailsSlice";
import { autoPilotLookupsSlice } from "../rtk-query/features/campaign/email-campaign/autopilotLookupsSlice";
import { signUpSlice } from "../rtk-query/features/auth/register/signUpSlice";
import { bankPerformanceMerticsSlice } from "../rtk-query/features/bankPerformanceMetrics/bankPerformanceMerticsSlice";
import { stripeSlice } from "../rtk-query/features/stripe/stripeSlice";
import { userManagementSlice } from "../rtk-query/features/usermanagement/userManagementSlice";
import { infiniteBankPerformanceMetricsSlice } from "../rtk-query/features/infiniteBankPerformanceMetricsSlice/infiniteBankPerformanceMetricsSlice";

const rootPersistConfig = {
  key: "root",
  storage: AsyncStorage,
  blacklist: ["authReducer"],
};

const authPersistConfig = {
  key: "authReducer",
  storage: storageSession,
  blacklist: ["somethingTemporary"],
};

const rootReducer = combineReducers({
  authReducer: persistReducer(authPersistConfig, authReducer),
  orgReducer: orgReducer,
  errorReducer: errorReducer,
  subscriptionReducer: subscriptionReducer,
  loaderReducer: loaderReducer,
  stripeReducer: stripeSlice.reducer,
  bankPerformanceMerticsSlice: bankPerformanceMerticsSlice.reducer,
  infiniteBankPerformanceMetricsReducer:
    infiniteBankPerformanceMetricsSlice.reducer,
  signUpReducer: signUpSlice.reducer,
  // pre_SignReducer: pre_SignReducer,
  // hidReducer: mainReportSlice,
  filterOptionReducer: filterOptionReducer,
  saveFilterReducer: saveFilterReducer,
  // opportunitiesReducer: opportunitiesReducer,
  // missingproductReducer: missingproductReducer,
  // secondaryconsumerReducer: secondaryconsumerReducer,
  // ghostconsumerReducer: ghostconsumerReducer,
  navigationHistoryReducer: navigationHistoryReducer,
  selectedFilterSlice: selectedFilterSlice,
  fhsDrilldownReducer: fhsDrilldownReducer,
  allCohortReducer: allCohortReducer,
  financialHealthScoreAnalysisReducer: financialHealthScoreAnalysisSlice,
  healthAnalysisReducer: healthAnalysisSlice,
  cohortSlice: cohortSlice,
  emailCampaigns: emailCampaignsSlice.reducer,
  emailTemplates: emailTemplatesSlice.reducer,
  consumerList: consumerListSlice.reducer,
  consumerListDetails: consumerListDetailsSlice.reducer,
  autopilotLookupsSlice: autoPilotLookupsSlice.reducer,
  userManagementSlice: userManagementSlice.reducer,
  [opportunitiesAnalysisSlice.reducerPath]: opportunitiesAnalysisSlice.reducer,
  // [missingProductsAnalysisSlice.reducerPath]:
  //   missingProductsAnalysisSlice.reducer,
  [ghostConsumerAnalysisSlice.reducerPath]: ghostConsumerAnalysisSlice.reducer,
  [secondaryConsumerAnalysisSlice.reducerPath]:
    secondaryConsumerAnalysisSlice.reducer,
  [topCompetitorsAnalysisSlice.reducerPath]:
    topCompetitorsAnalysisSlice.reducer,
  [fileAnalyticsAnalysisSlice.reducerPath]: fileAnalyticsAnalysisSlice.reducer,
  // [secondaryConsumerAnalysisSlice.reducerPath]:
  //   secondaryConsumerAnalysisSlice.reducer,
  [missedRevenueAnalysisSlice.reducerPath]: missedRevenueAnalysisSlice.reducer,
  [bondScoreAnalysisSlice.reducerPath]: bondScoreAnalysisSlice.reducer,
  // [efficiencyRatioAnalysisSlice.reducerPath]:
  //   efficiencyRatioAnalysisSlice.reducer,
  // [secondaryConsumerAnalysisSlice.reducerPath]:
  //   secondaryConsumerAnalysisSlice.reducer,
  // [topCompetitorsAnalysisSlice.reducerPath]:
  //   topCompetitorsAnalysisSlice.reducer,
  // [fileAnalyticsAnalysisSlice.reducerPath]: fileAnalyticsAnalysisSlice.reducer,
  [topMerchantsAnalysisSlice.reducerPath]: topMerchantsAnalysisSlice.reducer,
  [consumerDemographicsAnalysisSlice.reducerPath]:
    consumerDemographicsAnalysisSlice.reducer,
  [topPersonaAnalysisSlice.reducerPath]: topPersonaAnalysisSlice.reducer,
  // [newUserGrowthAnalysisSlice.reducerPath]: newUserGrowthAnalysisSlice.reducer,
  [trendsAnalysisSlice.reducerPath]: trendsAnalysisSlice.reducer,
  [registrationSlice.reducerPath]: registrationSlice.reducer,
  [autoPilotLookupsSlice.reducerPath]: autoPilotLookupsSlice.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableStateInvariant: false,
    }).concat([
      opportunitiesAnalysisSlice.middleware,
      // missingProductsAnalysisSlice.middleware,
      ghostConsumerAnalysisSlice.middleware,
      secondaryConsumerAnalysisSlice.middleware,
      topCompetitorsAnalysisSlice.middleware,
      fileAnalyticsAnalysisSlice.middleware,
      missedRevenueAnalysisSlice.middleware,
      bondScoreAnalysisSlice.middleware,
      // efficiencyRatioAnalysisSlice.middleware,
      topMerchantsAnalysisSlice.middleware,
      consumerDemographicsAnalysisSlice.middleware,
      topPersonaAnalysisSlice.middleware,
      // newUserGrowthAnalysisSlice.middleware,
      trendsAnalysisSlice.middleware,
      registrationSlice.middleware,
      autoPilotLookupsSlice.middleware,
      rtkQueryErrorLogger,
    ]),
});
export default store;
