import { DataTable } from "primereact/datatable";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { CustomSortIcon } from "../../Bank/report/widget/CustomSortIcon";
import exportimages from "../../../assets/images/exportImages";
import { Dropdown } from "primereact/dropdown";
import "./CustomDataTable.scss";
import searchLogo from "../../../assets/images/Search.png";

const CustomDataTable = ({
  filteredData,
  dataTableState,
  setDataTableState,
  searhPlaceholder,
  showPaginator,
  showSearch,
  showFilter,
  filterOptions,
  rowSelection,
  children,
}) => {
  const { currentPage, limit, totalRecords, sortObj, selectedFilter } =
    dataTableState;
  const loader = useSelector((state) => state?.loaderReducer);

  const templateForPaginator = {
    layout:
      "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport",
    CurrentPageReport: (options) => {
      let totalPage = Math.ceil(totalRecords / limit);
      return (
        <span className="mr-5">
          {currentPage} of {totalPage}
        </span>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 },
      ];
      return (
        <div className="d-none d-md-none  d-sm-none d-xs-none  d-lg-flex ">
          <span className="d-flex align-items-center">Per page</span>
          <Dropdown
            style={{ width: "auto" }}
            value={limit}
            options={dropdownOptions}
            onChange={handleDropdownValue}
            placeholder={"10"}
          />
          <span className="d-flex align-items-center">
            Displaying{" "}
            {loader?.loader ? "" : Math.min(currentPage * limit, totalRecords)}{" "}
            of {loader?.loader ? "" : totalRecords}
          </span>
        </div>
      );
    },
    PrevPageLink: (options) => {
      return (
        <div className="" style={{ padding: "0.5rem 1rem" }}>
          <button
            className={` p-paginator-element p-link ${
              currentPage === 1 ? "p-disabled" : ""
            }`}
            onClick={handlePrevPage}
            disabled={currentPage === 0}
          >
            <span className="p-paginator-icon pi pi-angle-left" />
          </button>
        </div>
      );
    },
    NextPageLink: (options) => {
      return (
        <div className="" style={{ padding: "0.5rem 1rem" }}>
          <button
            className={` p-paginator-element p-link  ${
              currentPage === Math.ceil(totalRecords / limit)
                ? "p-disabled"
                : ""
            }`}
            onClick={handleNextPage}
          >
            <span className="p-paginator-icon pi pi-angle-right" />
          </button>
        </div>
      );
    },
    FirstPageLink: (options) => {
      return (
        <div className="" style={{ padding: "0.5rem 1rem" }}>
          <button
            className={` p-paginator-element p-link ${
              currentPage === 1 ? "p-disabled" : ""
            }`}
            onClick={handleFirstPage}
            disabled={currentPage === 1}
          >
            <span className="p-paginator-icon pi pi-angle-double-left" />
          </button>
        </div>
      );
    },
    LastPageLink: (options) => {
      return (
        <div className="" style={{ padding: "0.5rem 1rem" }}>
          <button
            className={` p-paginator-element p-link ${
              currentPage === Math.ceil(totalRecords / limit)
                ? "p-disabled"
                : ""
            }`}
            onClick={handleLastPage}
            disabled={currentPage === Math.ceil(totalRecords / limit)}
          >
            <span className="p-paginator-icon pi pi-angle-double-right" />
          </button>
        </div>
      );
    },
    PageLinks: (options) => {
      if (options.totalPages === 1) {
        const pages = [];
        const maxPagesToShow = 3;

        let startPage = Math.max(
          1,
          currentPage - Math.floor(maxPagesToShow / 2),
        );
        let endPage = Math.min(
          Math.ceil(totalRecords / limit),
          startPage + maxPagesToShow - 1,
        );

        // Adjust startPage to ensure exactly maxPagesToShow links are shown
        if (endPage - startPage + 1 < maxPagesToShow) {
          startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }
        startPage = Math.max(1, startPage);
        for (let i = startPage; i <= endPage; i++) {
          pages.push(
            <button
              key={i}
              className={`p-paginator-element p-link p-paginator-page ${
                currentPage === i ? "p-highlight" : ""
              }`}
              onClick={() => handlePageChangeNew({ page: i })}
            >
              {i}
            </button>,
          );
        }
        return <div className="p-paginator-pages">{pages && pages}</div>;
      }
    },
  };

  const handleDropdownValue = (value) => {
    setDataTableState((prevState) => ({
      ...prevState,
      currentPage: 1,
      limit: value?.value,
    }));
  };

  const handleLastPage = () => {
    let lastPage = Math.ceil(totalRecords / limit);
    if (lastPage > 0) {
      setDataTableState((prevState) => ({
        ...prevState,
        currentPage: lastPage,
      }));
    }
  };
  const handleFirstPage = () => {
    setDataTableState((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  };
  const handlePrevPage = () => {
    if (currentPage > 0) {
      setDataTableState((prevState) => ({
        ...prevState,
        currentPage: currentPage - 1,
      }));
    }
  };
  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalRecords / limit)) {
      setDataTableState((prevState) => ({
        ...prevState,
        currentPage: currentPage + 1,
      }));
    }
  };
  const handlePageChangeNew = (event) => {
    if (event?.page !== currentPage) {
      setDataTableState((prevState) => ({
        ...prevState,
        currentPage: event?.page,
      }));
    }
  };
  const onSort = (field) => {
    let sortValue = field?.sortOrder === 1 ? "ASC" : "DESC";
    setDataTableState((prevState) => ({
      ...prevState,
      sortObj: {
        sortField: field?.sortField,
        sortOrder: sortValue,
        sortOrderValue: field?.sortOrder,
      },
    }));
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };
  const handleSearchData = (value) => {
    setDataTableState((prevState) => ({
      ...prevState,
      searchData: value,
      currentPage: 1,
    }));
  };

  const optimizedSearchData = useCallback(debounce(handleSearchData), [
    limit,
    sortObj,
    dataTableState?.searchData,
  ]);

  const HeaderForTable = () => {
    return (
      <div className="row align-items-stretch">
        {showSearch && (
          <div className="col-xl-4">
            <div className="search-field w-100 w-sm-100 w-xs-100 position-relative">
              <div className="static-img ">
                <img src={searchLogo} alt="icon" className="searchicon" />
              </div>
              <input
                type="text"
                className="form-control custom-input-field"
                onChange={(e) => {
                  optimizedSearchData(e?.target?.value);
                }}
                placeholder={`${searhPlaceholder}`}
                disabled={loader?.loader}
              />
            </div>
          </div>
        )}
        {showFilter && (
          <div className="col-xl-3 ms-5 custom-dropdown">
            <Dropdown
              value={selectedFilter}
              onChange={(e) => {
                setDataTableState((prevState) => ({
                  ...prevState,
                  selectedFilter: e.value,
                  currentPage: 1,
                }));
              }}
              options={
                filterOptions
                  ? [{ name: "All", code: "" }, ...filterOptions]
                  : [{ name: "All", code: "" }]
              }
              optionLabel="name"
              placeholder={"Filter"}
              className="listFilterDropdown filterCampaign"
              style={{ fontSize: "1.2rem" }}
            />
            <img
              src={exportimages?.Filter}
              alt="err"
              className="custom-dropdown-icon ms-3 filtericon text-muted"
            />
          </div>
        )}
      </div>
    );
  };
  return (
    <DataTable
      value={filteredData}
      className="custom-datatable p-custom-datatable"
      selectionMode={rowSelection ? "single" : ""}
      rows={filteredData?.length}
      paginator={showPaginator === true && filteredData?.length > 0 && "true"}
      paginatorTemplate={templateForPaginator}
      reflow="true"
      sortField={sortObj?.sortField}
      sortOrder={!loader?.loader && sortObj?.sortOrderValue}
      onSort={onSort}
      header={HeaderForTable}
      rowHover="true"
      scrollable="true"
      scrollHeight="flex"
      resizableColumns="true"
      sortIcon={CustomSortIcon}
      emptyMessage={() => (
        <h5 className="text-center pt-4 mt-1 mb-0 w-100">
          <div className="col-md-12 mb-8">
            <img src={exportimages.noDataFound} alt="no data" />
          </div>
          <div className="col-md-12">
            <h5>There is no data to display at the moment.</h5>
          </div>
        </h5>
      )}
    >
      {children}
    </DataTable>
  );
};

export default CustomDataTable;
