import { useEffect, useState } from "react";
import "./Auth/register/auth.component.scss";
import "./layout.component.scss";
import logo from "./../assets/logos/logo_white.svg";
import { useSelector } from "react-redux";
/* eslint-disable-next-line */
import { Header } from "./Common/Header/Header";
import loaderImage from "../assets/images/animation_loader.gif";
import { Chatbot } from "./Common/Chatbot/Chatbot";
import { useLocation } from "react-router";
import { useChameleonModalContext } from "../Context/ChameleonModal";
import { MobileDefault } from "./MobileDefault";
import { selectIsFHSReset } from "../rtk-query/features/dashboard/financialHealthScore/financialHealthScoreAnalysisSlice";
import { useMediaQuery } from "@mui/material";

export const AuthLayout = ({ children }) => {
  const authReducer = useSelector((state) => state.authReducer);
  const [NavBar, setNavBar] = useState(false);
  const loaderReducer = useSelector((state) => state.loaderReducer);
  const path = useLocation();
  const chameleonContext = useChameleonModalContext();
  const subscriptionReducer = useSelector((state) => state.subscriptionReducer);
  const orgReducer = useSelector((state) => state?.orgReducer);
  const isMobile = useMediaQuery("(max-width: 1224px)");

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow =
        loaderReducer.performaLoading ||
        loaderReducer.logoutLoading ||
        loaderReducer.loader
          ? "hidden"
          : "auto";
    }
  }, [
    loaderReducer.performaLoading,
    loaderReducer.logoutLoading,
    loaderReducer.loader,
  ]);

  useEffect(() => {
    let hideTimeout;

    const hideElement = () => {
      const element = document.querySelector(".chmln-list-container");
      if (element) {
        if (subscriptionReducer?.isPro === false) {
          element.style.display = "none";
        }
      } else {
        // If the element doesn't exist, wait for a short time and then check again
        hideTimeout = setTimeout(hideElement, 100); // Check again after 100 milliseconds
      }
    };

    // Initial call to start checking for the element
    hideElement();

    // Cleanup function to clear any pending timeouts if the component unmounts
    // Wait for the entire document to be loaded before checking for the element
    document.addEventListener("DOMContentLoaded", hideElement);

    // Cleanup function to remove event listener when the component unmounts
    return () => {
      document.removeEventListener("DOMContentLoaded", hideElement);
    };
  });

  useEffect(() => {
    const handleDashboardClick = (event) => {
      const containerListElement = document.querySelector(
        "#chmln-dom #chmln-list-6423ca97665897001783d13a .chmln-list .chmln-list-items",
      );

      const firstLiElement = containerListElement.querySelector("li button");

      const secondLiElement = containerListElement.querySelector(
        "li:nth-child(2) button",
      );

      orgReducer.orguid === "00000000-0000-aaaa-aaaa-000000000000"
        ? (secondLiElement.style.display = "none")
        : (secondLiElement.style.display = "flex");
      orgReducer.orguid === "00000000-0000-aaaa-aaaa-000000000000"
        ? (firstLiElement.style.display = "flex")
        : (firstLiElement.style.display = "none");
    };

    let intervalId = setInterval(() => {
      const containerElement = document.querySelector("#chmln-dom");

      if (containerElement) {
        const containerListElement = document.querySelector(
          "#chmln-dom #chmln-list-6423ca97665897001783d13a .chmln-list .chmln-list-items",
        );

        if (containerListElement) {
          const firstLiElement =
            containerListElement.querySelector("li button");

          const secondLiElement = containerListElement.querySelector(
            "li:nth-child(2) button",
          );

          orgReducer.orguid === "00000000-0000-aaaa-aaaa-000000000000"
            ? (secondLiElement.style.display = "none")
            : (secondLiElement.style.display = "flex");
          orgReducer.orguid === "00000000-0000-aaaa-aaaa-000000000000"
            ? (firstLiElement.style.display = "flex")
            : (firstLiElement.style.display = "none");
        }
        containerElement.addEventListener("click", handleDashboardClick);

        clearInterval(intervalId);
      }
    }, 100);
  }, [loaderReducer.loader]);

  if (authReducer?.isLogin && !path.pathname.includes("login")) {
    return !isMobile ? (
      <div className="desktop h-100">
        <div className="h-100">
          <div
            className="animatedLoader h-100 "
            style={{
              display: loaderReducer.loader ? "flex" : "none",
              position: loaderReducer.loader ? "fixed" : "static",
            }}
          >
            <img
              alt="loader"
              loading="lazy"
              src={loaderImage}
              className="loaderImg"
            />
          </div>
          <div className="d-flex flex-column flex-root  h-100">
            <div className="page d-flex flex-row flex-column-fluid h-100">
              <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
              >
                <Header NavBar={NavBar} setNavBar={setNavBar} />
                <div className="container mt-20" id="abc">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "100%", position: "absolute" }}>
          <div
            className="animatedLoader h-100 "
            style={{
              display: loaderReducer.logoutLoading ? "flex" : "none",
              position: loaderReducer.logoutLoading ? "fixed" : "static",
            }}
          >
            <img
              alt="loader"
              loading="lazy"
              src={loaderImage}
              className="loaderImg"
            />
          </div>
        </div>
        <div style={{ height: "100%", position: "absolute" }}>
          <div
            className="animatedLoader h-100 "
            style={{
              display: loaderReducer.performaLoading ? "flex" : "none",
              position: loaderReducer.performaLoading ? "fixed" : "static",
            }}
          >
            <img
              alt="loader"
              loading="lazy"
              src={loaderImage}
              className="loaderImg"
            />
          </div>
        </div>
        <div style={{ display: subscriptionReducer?.isPro ? "block" : "none" }}>
          <Chatbot />
        </div>
        <div
          style={{
            zIndex: "999899!important",
            height: chameleonContext.value ? "100vh !important" : "100%",
            overflow: chameleonContext.value ? "hidden!important" : "scroll",
            display: NavBar || chameleonContext.value ? "flex" : "none",
          }}
          className="drawer-overlay h-100"
          onClick={() => {
            setNavBar(false);
            chameleonContext.setChameleonValue(false);
          }}
        />
      </div>
    ) : (
      <MobileDefault />
    );
  } else if (
    !authReducer?.isLogin &&
    (path.pathname.includes("login") ||
      path.pathname.includes("register") ||
      path.pathname.includes("user-management-register") ||
      path.pathname.includes("email-verified"))
  ) {
    return (
      <>
        <div className="">
          <div className="d-flex flex-column flex-root">
            <div
              className="d-block d-md-flex bg-white blockForBigScreen  h-100"
              id="kt_login"
              style={{ minHeight: "100vh" }}
            >
              <div
                className="d-flex flex-column flex-lg-row-auto bg-bondai-auth pt-lg-0 col-12 col-sm-12 col-md-5"
                style={{ minHeight: "100vh" }}
              >
                <div className="lading_page_background_img h-100">
                  <div className="d-flex flex-column-auto flex-column text-center mb-md-5 logoContainer">
                    <span className="align-self-md-start text-start my-6 ms-6">
                      <img alt="logo" src={logo} className="img-fluid" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="login-content align-self-center  flex-lg-row-fluid d-flex flex-column justify-content-center h-100 py-10 px-10 p-lg-7 mx-auto w-100 bg-white">
                <div className="flex-column-fluid flex-center  p-xxxl-7 mx-auto mw-550px mw-xxl-650px w-100 h-100 routerContainer">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (!authReducer?.isLogin || path.pathname.includes("login")) {
    return !isMobile ? (
      <div className="desktop h-100">
        <div className="d-flex flex-column flex-root">
          <div
            className="d-block d-md-flex bg-white blockForBigScreen  h-100"
            id="kt_login"
            style={{ minHeight: "100vh" }}
          >
            <div
              className="d-flex flex-column flex-lg-row-auto bg-bondai-auth pt-lg-0 col-12 col-sm-12 col-md-5"
              style={{ minHeight: "100vh" }}
            >
              <div className="lading_page_background_img h-100">
                <div className="d-flex flex-column-auto flex-column text-center mb-md-5 logoContainer">
                  <span className="align-self-md-start text-start my-6 ms-6">
                    <img alt="logo" src={logo} className="img-fluid" />
                  </span>
                </div>
              </div>
            </div>
            <div className="login-content align-self-center  flex-lg-row-fluid d-flex flex-column justify-content-center h-100 py-10 px-10 p-lg-7 mx-auto w-100 bg-white">
              <div className="flex-column-fluid flex-center  p-xxxl-7 mx-auto mw-550px mw-xxl-650px w-100 h-100 routerContainer">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <MobileDefault />
    );
  }
};
