import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export function ResellerRestrictedRoutes() {
  const authReducer = useSelector((state) => state.authReducer);
  const userRole = authReducer?.userData?.role;
  const orgReducer = useSelector((state) => state.orgReducer);
  const healthAnalysisID = orgReducer?.healthAnalysisID?.uid;

  return userRole === "Bond-Admin" || userRole === "Reseller" ? (
    <Outlet />
  ) : (
    <Navigate to={`/bank/health-analysis/report/${healthAnalysisID}`} />
  );
}

export default ResellerRestrictedRoutes;
