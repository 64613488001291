import exportimages from "../assets/images/exportImages";

/* eslint-disable */
export const LANGUAGES = [
  { key: "id", display_value: "Bahasa Indonesia - Indonesian" },
  { key: "msa", display_value: "Bahasa Melayu - Malay" },
  { key: "ca", display_value: "CatalÃ  - Catalan" },
  { key: "cs", display_value: "ÄŒeÅ¡tina - Czech" },
  { key: "da", display_value: "Dansk - Danish" },
  { key: "de", display_value: "Deutsch - German" },
  { key: "en", display_value: "English US (Default)" },
  { key: "en-gb", display_value: "English UK - British English" },
  { key: "es", display_value: "EspaÃ±ol - Spanish" },
  { key: "eu", display_value: "Euskara - Basque (beta)" },
  { key: "fil", display_value: "Filipino" },
  { key: "fr", display_value: "FranÃ§ais - French" },
  { key: "ga", display_value: "Gaeilge - Irish (beta)" },
  { key: "gl", display_value: "Galego - Galician (beta)" },
  { key: "hr", display_value: "Hrvatski - Croatian" },
  { key: "it", display_value: "Italiano - Italian" },
  { key: "hu", display_value: "Magyar - Hungarian" },
  { key: "nl", display_value: "Nederlands - Dutch" },
  { key: "no", display_value: "Norsk - Norwegian" },
  { key: "pl", display_value: "Polski - Polish" },
  { key: "pt", display_value: "PortuguÃªs - Portuguese" },
  { key: "ro", display_value: "RomÃ¢nÄƒ - Romanian" },
  { key: "sk", display_value: "SlovenÄina - Slovak" },
  { key: "fi", display_value: "Suomi - Finnish" },
  { key: "sv", display_value: "Svenska - Swedish" },
  { key: "vi", display_value: "Tiáº¿ng Viá»‡t - Vietnamese" },
  { key: "tr", display_value: "TÃ¼rkÃ§e - Turkish" },
  { key: "el", display_value: "Î•Î»Î»Î·Î½Î¹ÎºÎ¬ - Greek" },
  { key: "bg", display_value: "Ð‘ÑŠÐ»Ð³Ð°Ñ€ÑÐºÐ¸ ÐµÐ·Ð¸Ðº - Bulgarian" },
  { key: "ru", display_value: "Ð ÑƒÑÑÐºÐ¸Ð¹ - Russian" },
  { key: "sr", display_value: "Ð¡Ñ€Ð¿ÑÐºÐ¸ - Serbian" },
  { key: "uk", display_value: "Ð£ÐºÑ€Ð°Ñ—Ð½ÑÑŒÐºÐ° Ð¼Ð¾Ð²Ð° - Ukrainian" },
  { key: "he", display_value: "×¢Ö´×‘Ö°×¨Ö´×™×ª - Hebrew" },
  { key: "ur", display_value: "Ø§Ø±Ø¯Ùˆ - Urdu (beta)" },
  { key: "ar", display_value: "Ø§Ù„Ø¹Ø±Ø¨ÙŠØ© - Arabic" },
  { key: "fa", display_value: "ÙØ§Ø±Ø³ÛŒ - Persian" },
  { key: "mr", display_value: "à¤®à¤°à¤¾à¤ à¥€ - Marathi" },
  { key: "hi", display_value: "à¤¹à¤¿à¤¨à¥à¤¦à¥€ - Hindi" },
  { key: "bn", display_value: "à¦¬à¦¾à¦‚à¦²à¦¾ - Bangla" },
  { key: "gu", display_value: "àª—à«àªœàª°àª¾àª¤à«€ - Gujarati" },
  { key: "ta", display_value: "à®¤à®®à®¿à®´à¯ - Tamil" },
  { key: "kn", display_value: "à²•à²¨à³à²¨à²¡ - Kannada" },
  { key: "th", display_value: "à¸ à¸²à¸©à¸²à¹„à¸—à¸¢ - Thai" },
  { key: "ko", display_value: "í•œêµ­ì–´ - Korean" },
  { key: "ja", display_value: "æ—¥æœ¬èªž - Japanese" },
  { key: "zh-cn", display_value: "ç®€ä½“ä¸­æ–‡ - Simplified Chinese" },
  { key: "zh-tw", display_value: "ç¹é«”ä¸­æ–‡ - Traditional Chinese" },
];

//RegEx Patterns
export const REGEX_PATTERN_URL = () =>
  /(https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/;
export const REGEX_PATTERN_UEL = () =>
  /^(http[s]?:\/\/){0,1}(www.){0,1}[a-zA-Z0-9.-]+.[a-zA-Z]{2,5}[.]{0,1}/;
export const REGEX_PATTERN_EMAIL = () =>
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const REGEX_PATTERN_NUMBER = () =>
  /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;
export const REGEX_PATTERN_WHITESPACE = () =>
  /^([A-z0-9!@#$%^&*().,<>{}[\]<>?_=+\-|;:\'\"\/])*[^\s]\1*$/;
export const PASSWORD_POLICY = (value) => {
  let MIN_LENGTH = 8;
  let MAX_LENGTH = 16;
  // let REGEX_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-â€œ!@#%&\/,><\â€™:;|_~`])\S{8,99}$/
  let REGEX_PATTERN =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{}[\]:;"'<>,.?/~`|\\/])[a-zA-Z\d!@#$%^&*()\-=_+{}[\]:;"'<>,.?/~`|\\/]{8,}$/;
  let errorStatus = false;
  let errorMessage = "";
  if (value.length > MAX_LENGTH) {
    errorStatus = true;
    errorMessage = "Password should be in between 8 to 16 characters.";
  } else if (value.length === 0) {
    errorStatus = true;
    errorMessage = "Enter valid password.";
  } else if (!REGEX_PATTERN.test(value)) {
    errorStatus = true;
    errorMessage =
      "Password should be at least 8 characters long or maximum limit 16 characters with at least 1 uppercase letter, 1 special character and 1 number.";
  }
  return { errorStatus, errorMessage };
};

export const CONFIRM_PASSWORD = (password, value) => {
  let errorMessage = "";
  let errorStatus = false;
  if (!(password === value)) {
    errorStatus = true;
    errorMessage = "Passwords should match.";
  }
  return { errorStatus, errorMessage };
};

export const getUnit = (score, type) => {
  let unit;
  let newScore = score;
  if (score != 0) {
    if (score >= 1000 && score <= 999999) {
      score = score / 1000;
      unit = "K";
    }
    // millions
    else if (score >= 1000000 && score <= 999999999) {
      score = score / 1000000;
      unit = "M";
    }
    // billions
    else if (score >= 1000000000 && score <= 999999999999) {
      score = score / 1000000000;
      unit = "B";
    }
    // not in above
    else {
      // score;
    }
  }
  if (type === "score") {
    return Math.floor(score) === score ? score : score.toFixed(2);
  }

  return unit;
};

export const SUBSCRIPTION = {
  NON_REGISTER_FI: "non_register_fi",
  FREEMIUM_WITHOUT_FHS: "freemium_without_fhs",
  FREEMIUM_WITH_FHS: "freemium_with_fhs",
  PRO: "pro",
  MARKETING: "marketing",
};

export const OPPORTUNITIES = [
  {
    key: "credit_card",
    name: "Credit Card",
    icon: exportimages.icon_credit_card,
    color: "#27CAE3",
  },
  {
    key: "consumer_loan",
    name: "Consumer Loan",
    icon: exportimages.icon_consumer_loan,
    color: "#5CAEFF",
  },
  {
    key: "mortgage",
    name: "Mortgage",
    icon: exportimages.icon_mortgage,
    color: "#FE6682",
  },
  {
    key: "auto_loan",
    name: "Auto Loan",
    icon: exportimages.icon_auto_loan,
    color: "#6C7DE5",
  },
  {
    key: "student_loan",
    name: "Student Loan",
    icon: exportimages.icon_student_loan,
    color: "#FF6DE8",
  },
  {
    key: "personal_loan",
    name: "Personal Loan",
    icon: exportimages.icon_personal_loan,
    color: "#FFB30E",
  },
  {
    key: "business_loan",
    name: "Business Loan",
    icon: exportimages.icon_business_loan,
    color: "#A099F3",
  },
];
export const CONSUMERS = [
  {
    key: "internal_deposits",
    name: "Internal Deposit Opportunity",
    icon: null,
    color: "#687AE5",
    bgColor: "",
  },
  {
    key: "external_deposits",
    name: "External Deposit Available",
    icon: null,
    color: "#45A1FF",
    bgColor: "",
  },
];

export const CONSUMERS_TYPES = [
  {
    key: "internal_primary_deposits",
    name: "Internal Primary Deposits",
    icon: exportimages.icon_secondary_consumer_without_dda,
    color: "#4B60DF",
    zAxis: 175,
  },
  {
    key: "internal_secondary_deposits",
    name: "Internal Secondary Deposits",
    icon: exportimages.icon_secondary_consumer_with_dda,
    color: "#7588F8",
    zAxis: 175,
  },
  {
    key: "external_deposits",
    name: "External Deposits",
    icon: exportimages.icon_primary_consumer_with_dda,
    color: "#5EABF8",
    zAxis: 75,
  },
];

export const RESPONSE = {
  SUCCESS: {
    status_code: 200,
    status: "SUCCESS",
  },
  ERROR: {
    status: "ERROR",
  },
};

const templates = JSON.parse(process.env.REACT_APP_CAMPAIGN_SAMPLE_TEMPLATES);

export const SAMPLE_TEMPLATES = [
  {
    sampleTemplateObj: [
      {
        id: templates[1],
        heading: "Ready to gain more!!",
        category: "Business Loan",
        src: "sample_template_one",
      },
      {
        id: templates[2],
        heading: "Escape High-Interest Rate",
        category: "Credit Card",
        src: "sample_template_two",
      },
      {
        id: templates[0],
        heading: "Binge your favourite Netflix for free.",
        category: "Savings Account",
        src: "sample_template_three",
      },
    ],
  },
];

export const PROXIMITY_STATES = [
  { name: "Alabama", key: "alabama" },
  { name: "Alaska", key: "alaska" },
  { name: "Arizona", key: "arizona" },
  { name: "Arkansas", key: "arkansas" },
  { name: "California", key: "california" },
  { name: "Colorado", key: "colorado" },
];

export const FI_TYPES = [
  { name: "Credit Union: Federal", key: "cu-f" },
  { name: "Credit Union: State", key: "cu-s" },
];

export const CHARTER_TYPES = [
  { name: "Industrial", key: "industrial" },
  { name: "Commercial", key: "commercial" },
];

export const Campaign_Statistics = [
  {
    key: "processed_Count",
    name: "Targeted Consumers",
    value: 0,
    icons: exportimages?.icon_users,
  },
  {
    key: "delivered_Count",
    name: "Delivered",
    value: 0,
    icons: exportimages?.icon_ongoing_campaign,
  },
  {
    key: "opened_Count",
    name: "Email Viewed",
    value: 0,
    icons: exportimages?.icon_approved_campaign,
  },
  {
    key: "clicked_Count",
    name: "CTA Clicks",
    value: 0,
    icons: exportimages?.icon_ongoing_campaign,
  },
  {
    key: "bounced_Count",
    name: "Bounced Emails",
    value: 0,
    icons: exportimages?.icon_mail_block,
  },
  {
    key: "unsubscribed_Count",
    name: "Unsubscribed",
    value: 0,
    icons: exportimages?.icon_mail_fail,
  },
];

// export const FHS_SEGMENTS = [
//   {
//     key: "total_assets",
//     title: "Total Assets",
//     description: "Combined value of all assets owned or controlled.",
//     icon: exportimages.total_assets_icon,
//   },
//   {
//     key: "deposit_growth",
//     title: "Deposit Growth",
//     description:
//       "Displays the year-to-date (YTD) variation in deposited funds.",
//     icon: exportimages.deposit_growth_icon,
//   },
//   {
//     key: "loan_growth",
//     title: "Loan Growth",
//     description: "Shows the year-to-date (YTD) change in loan amounts.",
//     icon: exportimages.loan_growth_icon,
//   },
//   {
//     key: "efficiency_ratio",
//     title: "Efficiency Ratio",
//     description: "Measures a company's cost effectiveness.",
//     icon: exportimages.efficiency_ratio_icon,
//   },
//   {
//     key: "nii",
//     title: "Net Interest Income (NII)",
//     description:
//       "Revenue generated from interest earned on loans minus the interest paid on deposits (YTD).",
//     icon: exportimages.net_interest_income_nii_icon,
//   },
//   {
//     key: "nim",
//     title: "Net Interest Margin (NIM)",
//     description:
//       " Percentage of net interest income relative to the average earning assets.",
//     icon: exportimages.net_interest_margin_nim_icon,
//   },
//   {
//     key: "gross_npa",
//     title: "Gross NPA",
//     description: "Indicates bad loans before recoveries and write-offs (YTD).",
//     icon: exportimages.gross_npa_icon,
//   },
//   {
//     key: "npa_to_total_loans_ratio",
//     title: "NPA to Total Loans Ratio",
//     description: "Reflects bad loans after recoveries and write-offs (YTD).",
//     icon: exportimages.npa_to_total_loans_ratio_icon,
//   },
// ];
export const FHS_SEGMENTS = [
  {
    key: "capital_adequacy_ratio",
    title: "Capital Adequacy Ratio (CAR)",
    description:
      "Percentage of financial institution's capital to its risk-weighted assets (YTD)",
    icon: exportimages.loan_growth_icon,
    symbol: "%",
    is_reverse: "false",
  },
  {
    key: "return_on_assets",
    title: "Return on Assets (ROA)",
    description: "Profitability in relation to total assets (YTD)",
    icon: exportimages.total_assets_icon,
    symbol: "%",
    is_reverse: "false",
  },
  {
    key: "return_on_equity",
    title: "Return on Equity (ROE)",
    description: "Profitability in relation to total equity (YTD)",
    icon: exportimages.deposit_growth_icon,
    symbol: "%",
    is_reverse: "false",
  },
  {
    key: "efficiency_ratio",
    title: "Efficiency Ratio",
    description: "Measures the financial institution's cost effectiveness",
    icon: exportimages.efficiency_ratio_icon,
    symbol: "%",
    is_reverse: "true",
  },
  {
    key: "net_interest_income",
    title: "Net Interest Income (NII)",
    description:
      "Reflects revenue generated from core lending and borrowing activities",
    icon: exportimages.net_interest_income_nii_icon,
    symbol: "$",
    is_reverse: "false",
  },
  {
    key: "net_interest_margin",
    title: "Net Interest Margin (NIM)",
    description:
      "Profitability by measuring the difference between interest income and expenses relative to earning assets",
    icon: exportimages.net_interest_margin_nim_icon,
    symbol: "%",
    is_reverse: "true",
  },
  {
    key: "non_interest_expense",
    title: "Non Interest Expense",
    description:
      "Represents operating costs that are separate from interest expenses",
    icon: exportimages.gross_npa_icon,
    symbol: "$",
    is_reverse: "true",
  },
  {
    key: "npa_to_total_loans_ratio",
    title: "NPA to Total Loans Ratio",
    description: "Reflects bad loans after recoveries and write-offs (YTD).",
    icon: exportimages.npa_to_total_loans_ratio_icon,
    symbol: "%",
    is_reverse: "true",
  },
];

export const BPM_KEY = {
  client_uid: "client_uid",
  financial_institute: "financial_institute",
  financial_health_score: "financial_health_score",
  net_interest_income: "net_interest_income",
  earning_accretion: "earning_accretion",
  total_loans: "total_loans",
  total_deposits: "total_deposits",
  asset_quality: "asset_quality",
};

export const BANK_PERFORMANCE_METRICS = [
  {
    key: BPM_KEY.client_uid,
    title: "Financial Institution",
    isVisable: false,
    sortable: false,
    colspan: 1,
  },
  {
    key: BPM_KEY.financial_institute,
    title: "Financial Institution",
    isVisable: true,
    width: 15,
    header_css: "sticky-left",
    class: "sticky-left",
    sortable: false,
    colspan: 1,
  },
  {
    key: BPM_KEY.financial_health_score,
    title: "Financial Health Score",
    is_reverse: "false",
    isVisable: true,
    width: 10,
    header_css: "text-center",
    sortable: false,
    colspan: 2,
  },
  {
    key: BPM_KEY.net_interest_income,
    title: "Net Interest Income",
    is_reverse: "false",
    isVisable: true,
    symbol: "$",
    width: 16,
    header_css: "text-center",
    sortable: false,
    colspan: 2,
  },
  {
    key: BPM_KEY.earning_accretion,
    title: "Earnings Accretion",
    is_reverse: "false",
    isVisable: true,
    symbol: "$",
    width: 16,
    header_css: "text-center",
    sortable: false,
    colspan: 2,
  },
  {
    key: BPM_KEY.total_loans,
    title: "Total Loans",
    is_reverse: "false",
    isVisable: true,
    symbol: "$",
    width: 16,
    header_css: "text-center",
    sortable: false,
    colspan: 2,
  },
  {
    key: BPM_KEY.total_deposits,
    title: "Total Deposits",
    is_reverse: "false",
    isVisable: true,
    symbol: "$",
    width: 16,
    header_css: "text-center",
    sortable: false,
    colspan: 2,
  },
  {
    key: BPM_KEY.asset_quality,
    title: "Asset Quality",
    is_reverse: "false",
    isVisable: true,
    width: 10,
    header_css: "text-center",
    sortable: false,
    colspan: 2,
  },
];

// export const SEGMENT_INFLUENCING_FACTOR = [
//   {
//     key: "return_on_assets",
//     title: "Return On Assets (ROA)",
//     description:
//       "Indicates how efficiently a bank uses its assets to generate profit.",
//     icon: exportimages.return_on_equity,
//   },
//   {
//     key: "return_on_equity",
//     title: "Return On Equity (ROE)",
//     description:
//       "Measures a banks profitability by revealing how much profit it generates with shareholders' equity.",
//     icon: exportimages.asset_ratio,
//   },
//   {
//     key: "total_capital_ratio",
//     title: "Total Capital Ratio",
//     description:
//       "Measures a bank's financial strength, showing the proportion of a bank’s capital to its risk-weighted asset.",
//     icon: exportimages.net_operating_income,
//   },
// ];

export const SEGMENT_INFLUENCING_FACTOR = [
  {
    key: "total_assets",
    title: "Total Assets",
    description: "Combined value of all assets owned or controlled",
    icon: exportimages.return_on_equity,
    is_reverse: "false",
    symbol: "$",
  },
  {
    key: "total_loans",
    title: "Total Loans",
    description: "Combined value of all loans & leases",
    icon: exportimages.asset_ratio,
    is_reverse: "false",
    symbol: "$",
  },
  {
    key: "total_deposits",
    title: "Total Deposits",
    description: "Combined value of all deposits",
    icon: exportimages.net_operating_income,
    is_reverse: "false",
    symbol: "$",
  },
];

export const HEALTH_ANALYSIS_STATUS = [
  {
    STATUS: "DRAFT",
    PERCENTAGE: 0,
    MESSAGE: "Started Calculating Draft Bank Health Score",
  },
  {
    STATUS: "NEW",
    PERCENTAGE: 10,
    MESSAGE: "Started Calculating New Bank Health Score",
  },
  {
    STATUS: "DATA_INGESTION-START",
    PERCENTAGE: 10,
    MESSAGE: "Investigation File Uploaded",
  },
  {
    STATUS: "DATA_INGESTION-END",
    PERCENTAGE: 10,
    MESSAGE: "Investigation File Uploaded",
  },
  {
    STATUS: "DATA_VALIDATION-START",
    PERCENTAGE: 20,
    MESSAGE: "Validating File Uploaded",
  },
  {
    STATUS: "DATA_VALIDATION-END",
    PERCENTAGE: 20,
    MESSAGE: "Validating File Uploaded",
  },
  {
    STATUS: "DATA_ENRICHMENT-START",
    PERCENTAGE: 30,
    MESSAGE: "Enriching Uploaded Data",
  },
  {
    STATUS: "DATA_ENRICHMENT-END",
    PERCENTAGE: 40,
    MESSAGE: "Enriching Uploaded Data",
  },
  {
    STATUS: "DATA_SCORING-START",
    PERCENTAGE: 50,
    MESSAGE: "Scoring Uploaded Data",
  },
  {
    STATUS: "DATA_SCORING-END",
    PERCENTAGE: 50,
    MESSAGE: "Scoring Uploaded Data",
  },
  {
    STATUS: "DATA_ANALYSIS-START",
    PERCENTAGE: 60,
    MESSAGE: "Analysing Your Data",
  },
  {
    STATUS: "DATA_ANALYSIS-L1_CARD_DATA",
    PERCENTAGE: 60,
    MESSAGE: "Analysing Your Data Card",
  },
  {
    STATUS: "DATA_ANALYSIS-RUNNING_BALANCE",
    PERCENTAGE: 70,
    MESSAGE: "Analysing Your Running Balance",
  },
  {
    STATUS: "DATA_ANALYSIS-BOND_SCORE",
    PERCENTAGE: 80,
    MESSAGE: "Analysing Your Bond Score",
  },
  {
    STATUS: "DATA_ANALYSIS-PERSONA",
    PERCENTAGE: 90,
    MESSAGE: "Analysing Your Persona",
  },
  {
    STATUS: "Complete",
    PERCENTAGE: 100,
    MESSAGE: "Results are ready!",
  },
  {
    STATUS: "ERROR",
    PERCENTAGE: 0,
    MESSAGE: "Opps! Something Went Wrong!",
  },
];

export const getHealthAnalysisStatusList = () => {
  return HEALTH_ANALYSIS_STATUS.filter(
    (item) => item.PERCENTAGE > 0 && item.PERCENTAGE < 100,
  ).map((item) => item.STATUS);
};

export const getHealthAnalysisStatus = (status) => {
  return HEALTH_ANALYSIS_STATUS.filter((item) => item?.STATUS === status);
};

export const cohortObj = [
  {
    icon: "cohort_percentile",
    cohort_title: "Percentile",
    cohort_description:
      "Percentile represents the relative ranking of a bank's FHS within the selected cohort.",
  },
  {
    icon: "cohort_avg",
    cohort_title: "Cohort Average",
    cohort_description:
      "It is the mean Financial Health Score(FHS) of all banks in the selected cohort.",
  },
  {
    icon: "cohort_institution",
    cohort_title: "Institutions",
    cohort_description:
      "Institution count refers to the total number of banks included in the selected cohort",
  },
];

export const cohortDataTemplate = [
  {
    icon: exportimages?.cohort_percentile,
    cohort_title: "Percentile",
    cohort_description:
      "Represents a position within a cohort, indicating the percentage of values that fall below a specific score. Providing insights into relative standing or performance.",
    cohort_score: null,
    id: 1,
  },
  {
    icon: exportimages?.cohort_avg,
    cohort_title: "Cohort Average",
    cohort_description:
      "Represents the average Financial Health Score within a specified cohort, providing a summary measure of the group's overall financial health.",
    cohort_score: null,
    id: 2,
  },
  {
    icon: exportimages?.cohort_institution,
    cohort_title: "Institutions",
    cohort_description:
      "Represents the total number of financial institutions within the specified cohort, offering insight into the size and diversity of the group.",
    cohort_score: null,
    id: 3,
  },
];

export const fhsGaugeStatus = [
  {
    status: "good",
    description: "Your bank is doing well!",
  },
  {
    status: "optimal",
    description: "Your bank is at optimal performance!",
  },
  {
    status: "requires action",
    description: "Your bank requires action.",
  },
  {
    status: "needs attention",
    description: "Your bank needs some attention.",
  },
];
