import exportimages from "../assets/images/exportImages";
import "./Auth/register/auth.component.scss";
import "./layout.component.scss";
import logo from "./../assets/logos/logo_white.svg";

export const MobileDefault = () => {
  return (
    <div className="mobile h-100" style={{ backgroundColor: "#1C1468" }}>
      <div className="noAnalysisBlock h-100">
        <div className="p-8">
          <img alt="logo" src={logo} className="img-fluid" />
        </div>
        <div className=" text-center w-100 d-flex flex-column align-items-center justify-content-between">
          <div>
            <div className="col-md-12 pt-10 4">
              <div className="col-md-12">
                <img
                  alt="logo"
                  src={exportimages.mobile_static_img}
                  className="group_3"
                  style={{ width: "52vw" }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <p
                className="blockTitle flex-grow-1 mb-6 mt-6 px-20"
                style={{
                  fontWeight: 1000,
                  fontSize: "1.9rem",
                  color: "#F0F2FC",
                }}
              >
                Optimized Experience on Desktop
              </p>
              <p className="mb-0 d-flex justify-content-center align-items-center">
                <span
                  style={{
                    width: "89%",
                    fontSize: "1rem",
                    color: "#9DA8EE",
                  }}
                >
                  Currently, our portal is optimized for desktop use. For the
                  best experience, we recommend accessing Autopilot on a desktop
                  or laptop computer. We appreciate your understanding and look
                  forward to providing you with a seamless experience.
                </span>
              </p>
            </div>
          </div>
          <div />
        </div>
      </div>
      <div className="sticky-bottom text-center">
        Thank you for visiting Autopilot!
      </div>
    </div>
  );
};
