import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { API_STATUS } from "../../sliceUtil";
import { _delete, _get, _post, _put } from "../../../../api/apiClient";
import store from "../../../../app/store";
import { CustomError } from "../../../../api/CustomError";
import { CustomSuccess } from "../../../../api/CustomSuccess";
import { setLoaderFalse, setLoaderTrue } from "../../../../Redux/loaderSlice";

const initialConsumerListState = {
  consumerList: [],
  isLoading: API_STATUS.STATUS_IDLE,
  totalConsumerList: 0,
};

const base_url = process.env.REACT_APP_API_URL;
const _orgClientId = () => {
  const state = store.getState();
  return state?.orgReducer?.selectedFI?.orgClientId;
};

export const fetchConsumerList = createAsyncThunk(
  "consumersList/fetchConsumerList",
  async ({ paginationObj, disableLoader }, thunkAPI) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Fetched Consumer successfully.",
        errorMessage: "Failed to fetch consumer!",
      };
      const _searchby = paginationObj?.searchData
        ? `searchby=${encodeURIComponent(paginationObj?.searchData)}`
        : "";
      const _limit = paginationObj?.limit
        ? `&limit=${paginationObj?.limit}`
        : "&limit=0";
      const _offset = paginationObj?.currentPage
        ? `&offset=${(paginationObj?.currentPage - 1) * paginationObj?.limit}`
        : "&offset=0";
      const _status = paginationObj?.selectedFilter?.code
        ? `&Status=${paginationObj?.selectedFilter?.code}`
        : "";
      const _orderby = paginationObj?.sortObj?.sortOrder
        ? `&orderby=${paginationObj?.sortObj?.sortOrder}`
        : "&orderby=DESC";
      const response = await _get(
        `${base_url}/${_orgClientId()}/campaign/customer?${_searchby}${_limit}${_offset}${_status}${_orderby}`,
      );

      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const {
        data: customerlistRes,
        error,
        message,
        status,
        statusCode,
        responseType,
      } = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });
      // setTimeout(() => {
        thunkAPI.dispatch(setLoaderFalse());
      // }, 3000);
      return {
        customerlistRes,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createConsumerList = createAsyncThunk(
  "consumersList/createConsumerList",
  async ({ data, disableLoader }, thunkAPI) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Created consumer list successfully.",
        errorMessage: "Failed to create consumer list!",
      };
      const response = await _post(
        `${base_url}/${_orgClientId()}/campaign/customer`,
        data,
        config,
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const {
        data: customerlistId,
        error,
        message,
        status,
        statusCode,
        responseType,
      } = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });
      thunkAPI.dispatch(setLoaderFalse());
      return {
        customerlistId,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (err) {
      if (disableLoader) thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateConsumerList = createAsyncThunk(
  "consumersList/updateConsumerList",
  async (
    { orgClientId, selectedRowId, paginationObj, data, disableLoader },
    thunkAPI,
  ) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Updated Consumer successfully.",
        errorMessage: "Failed to update consumer!",
      };
      const response = await _put(
        `${base_url}/${_orgClientId()}/campaign/customer/${selectedRowId}`,
        data,
        config,
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const _response = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });
      await thunkAPI.dispatch(
        fetchConsumerList({ orgClientId, paginationObj }),
      );
      thunkAPI.dispatch(setLoaderFalse());
      return _response;
    } catch (err) {
      if (disableLoader) thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteConsumerList = createAsyncThunk(
  "consumersList/deleteConsumerList",
  async (
    { orgClientId, selectedRowId, paginationObj, data, disableLoader },
    thunkAPI,
  ) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Consumer List deleted successfully!",
        errorMessage: "Failed to delete consumer!",
      };

      const response = await _delete(
        `${base_url}/${_orgClientId()}/campaign/customer/${selectedRowId}`,
        config,
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const _response = CustomSuccess(response, {
        ...config,
      });
      await thunkAPI.dispatch(
        fetchConsumerList({ orgClientId, paginationObj }),
      );
      thunkAPI.dispatch(setLoaderFalse());
      return _response;
    } catch (err) {
      if (disableLoader) thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const consumerListSlice = createSlice({
  name: "consumersList",
  initialState: initialConsumerListState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsumerList.pending, () => ({
        isLoading: API_STATUS.STATUS_LOADING,
      }))
      .addCase(fetchConsumerList.fulfilled, (_state, action) => ({
        isLoading: API_STATUS.STATUS_SUCCESS,
        consumerList: action.payload?.customerlistRes?.customerlistRes,
        totalConsumerList: action.payload?.customerlistRes?.totalCustomerLists,
      }))
      .addCase(fetchConsumerList.rejected, (_state, action) => {
        return {
          isLoading: API_STATUS.STATUS_FAILURE,
          message: action.error.message || "Error while fetching consumerlist",
          consumerList: [],
        };
      });
  },
});

export const selectConsumerList = (_state) => _state?.consumerList;

export const {} = consumerListSlice.actions;
